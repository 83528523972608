import { FC } from "react";
import Header from "./components/Header";
import LaunchCountdown from "./components/LaunchCountdown";

interface AppProps { };

const App: FC<AppProps> = () => {
  return (
    <>
      <Header />
      <LaunchCountdown
        date="2025-05-03:15:00:00"
        project="Nano Fly I"
      />
    </>
  );
};

export default App;
