import { FC } from "react";
import { Typewriter } from "react-simple-typewriter";
import logo from "../images/logo.svg";

interface HeaderProps { };

const Header: FC<HeaderProps> = () => {
  return (
    <header>
      <img
        src={logo}
        alt="Swispace logo"
        className="logo"
      />
      <h1>
        We love <span className="red">
          <Typewriter
            words={[
              "space",
              "to innovate",
              "to explore",
              "science",
              "coding",
            ]}
            delaySpeed={2000}
            typeSpeed={75}
            deleteSpeed={25}
            loop
          />
        </span>
      </h1>
      <h3>We are the Swiss Space Agency</h3>
    </header>
  )
};

export default Header;
