import { FC } from "react";
import Countdown from "react-countdown";

interface LaunchCountdownProps {
  date: string;
  project: string;
};

const LaunchCountdown: FC<LaunchCountdownProps> = ({
  date,
  project,
}) => {
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <div>
        <span className="days">
          {days >= 10 ? days : "0" + days}
          <label>days</label>
        </span>
        <span className="hours">
          {hours >= 10 ? hours : "0" + hours}
          <label>hours</label>
        </span>
        <span className="minutes">
          {minutes >= 10 ? minutes : "0" + minutes}
          <label>minutes</label>
        </span>
        <span className="seconds">
          {seconds >= 10 ? seconds : "0" + seconds}
          <label>seconds</label>
        </span>
      </div>
    );
  };

  return (
    <div className="countdown">
      <Countdown
        date={new Date(date).getTime()}
        renderer={renderer}
        autoStart
      />
      <h4>
        Until <div className="red">{project}</div>'s launch
      </h4>
    </div>
  )
};

export default LaunchCountdown;
